import {list} from '../../../../domain/reboquista/services'

const searchReboquista = function () {
  console.log('click window search reboquista...')
  let columns = [
    {label: 'ID', value: 'id'},
    {label: 'Nome', value: (v) => v.pessoa.name}
  ]
  console.log('XXX', this.solicitacao)
  let solicitacao
  if (this.solicitacao) {
    solicitacao = this.solicitacao
    columns.push({label: 'Custo', value: (v) => 'R$ ' + this.$options.filters.moeda(v.valor || 0)})
  }
  let window = 'windowSearchReboquista'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Buscar Reboquista',
    width: '800',
    height: '600',
    minHeight: '500',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden bg-white',
    props: {
      columns: columns,
      fetchData: (terms, filters, done) => {
        let rowsPerPage = 100
        let extra
        if (solicitacao) {
          extra = `&origem=${solicitacao.origem.localizacao}&destino=${solicitacao.destino.localizacao}`
        }
        if (typeof terms === 'undefined') {
          terms = ''
        }
        if (typeof extra === 'undefined') {
          extra = ''
        }
        list(rowsPerPage, filters.page, '&sortBy=id&descending=desc&search=' + terms + extra)
          .then(({data}) => {
            let filter = function (terms, {field, list}) {
              const token = terms.toLowerCase()
              return list
              // return list.filter(item => ('' + item[field]).toLowerCase().includes(token))
            }

            let filteredData = filter(terms, {field: 'nome', list: data.result})

            done(filteredData, {
              page: filters.page,
              rowsNumber: data.total, // response.totalRows,
              rowsPerPage: rowsPerPage
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }, () => import('../../../layout/window/WindowSearch.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        selected: (wid, val) => {
          this.findedData = val
          this.model = val.id
          this.__emit(val)
          this.description = val.id + ' - ' + val.pessoa.name
          setTimeout(() => {
            this.$refs.input && this.$refs.input.focus()
          }, 200)
        }
      })
      console.log(wid)
    }) // return wid
}

export default searchReboquista
